"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn, addClass, removeClass, findAllIn, on, hasClass} from '@elements/dom-utils';
import {getPrefixedDataSet} from "@elements/data-set-utils";


const selectors = {
    video: '.js-hero-video__video',
    button: '.js-hero-video__controls',
    base: '.js-hero-video',
    pauseIcon: '.js-hero-video__pause-icon',
    playIcon: '.js-hero-video__play-icon'
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let video = findIn(selectors.video, baseElement);
        let button = findIn(selectors.button, baseElement);
        let playIcon = findIn(selectors.playIcon, baseElement);
        let pauseIcon = findIn(selectors.pauseIcon, baseElement);

        if(video && button) {
            on('click', function () {
                if (video.paused) {
                    video.play();
                    if (hasClass('d-none', pauseIcon)) {
                        addClass('d-none', playIcon);
                        removeClass('d-none', pauseIcon);
                    }
                } else if (video.played) {
                    video.pause();
                    if (hasClass('d-none', playIcon)) {
                        addClass('d-none', pauseIcon);
                        removeClass('d-none', playIcon);
                    }
                }
            }, button);
        }
    });
}
